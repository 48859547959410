<template>
  <nav id="sidebar" class="sidebar" :class="{ collapsed: isHide }">
    <div class="sidebar-content">
      <a class="sidebar-brand" href="/">
        <b-badge class="versionBadge">{{ versionName }} - {{ env }}</b-badge>
        <br />
        <span class="align-middle">
          Callia Admin
        </span>
      </a>
      <ul class="sidebar-nav">
        <li class="sidebar-header section-title">Delivery</li>
        <li class="sidebar-item">
          <a href="#rates-list" data-toggle="collapse" class="sidebar-link collapsed">
            <shopping-bag-icon size="1.5x" class="align-middle"></shopping-bag-icon>
            <span class="align-middle">Rates</span>
          </a>
          <ul id="rates-list" class="sidebar-dropdown list-unstyled collapse" data-parent="#sidebar">
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/secure/zone-delivery-rate">Zone Delivery Rate
              </router-link>
            </li>
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/secure/special-zone-delivery-rate">Special Zone Delivery Rate
              </router-link>
            </li>
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/secure/delivery-rate">Delivery Rate
              </router-link>
            </li>
          </ul>
        </li>
        <li class="sidebar-item">
          <a href="#region-list" data-toggle="collapse" class="sidebar-link collapsed">
            <map-icon size="1.5x" class="align-middle"></map-icon>
            <span class="align-middle">Places</span>
          </a>
          <ul id="region-list" class="sidebar-dropdown list-unstyled collapse" data-parent="#sidebar">
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/secure/region">Region</router-link>
            </li>
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/secure/zone">Zone</router-link>
            </li>
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/secure/town">Town</router-link>
            </li>
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/secure/postalcode">Postal Code</router-link>
            </li>
          </ul>
        </li>
        <!--          <li class="sidebar-item">-->
        <!--            <a-->
        <!--                href="#schedules"-->
        <!--                data-toggle="collapse"-->
        <!--                class="sidebar-link collapsed"-->
        <!--            >-->
        <!--              <truck-icon size="1.5x" class="align-middle"></truck-icon>-->
        <!--              <span class="align-middle">Schedules</span>-->
        <!--            </a>-->
        <!--            <ul-->
        <!--                id="schedules"-->
        <!--                class="sidebar-dropdown list-unstyled collapse"-->
        <!--                data-parent="#sidebar"-->
        <!--            >-->
        <!--              <li class="sidebar-item">-->
        <!--                <router-link class="sidebar-link" to="/secure/schedule/drivers"-->
        <!--                >Drivers-->
        <!--                </router-link-->
        <!--                >-->
        <!-- <a class="sidebar-link" href="#">Drivers</a> -->
        <!--              </li>-->
        <!--              <li class="sidebar-item">-->
        <!--                <a class="sidebar-link" href="#">Couriers</a>-->
        <!--              </li>-->
        <!--            </ul>-->
        <!--          </li>-->
        <li class="sidebar-item">
          <a href="#orders" data-toggle="collapse" class="sidebar-link collapsed">
            <package-icon size="1.5x" class="align-middle"></package-icon>
            <span class="align-middle">Orders</span>
          </a>
          <ul id="orders" class="sidebar-dropdown list-unstyled collapse" data-parent="#sidebar">
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/secure/orders/manual-fullfillment">Manual Fulfillment
              </router-link>
            </li>
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/secure/orders/cutoffs">CutOffs
              </router-link>
            </li>
            <!-- <li class="sidebar-item">
              <router-link class="sidebar-link" to="/secure/rules/dates-closed"
              >Dates Closed
              </router-link>
            </li>
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/secure/rules/dates-available"
              >Dates Available
              </router-link>
            </li> -->
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/secure/orders/latest-order-pulls">Latest OrderPulls
              </router-link>
            </li>
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/secure/orders/replacement-order-automation">Order Replacement
                Automation
              </router-link>
            </li>
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/secure/orders/checkout-automation">Checkout Automation
              </router-link>
            </li>
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/secure/orders/bulk-order-link-creator">Bulk Order Link Creator
              </router-link>
            </li>
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/secure/orders/upgrade-automation">Upgrade Automation
              </router-link>
            </li>
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/secure/orders/mass-adjust-tags">Mass Adjust Tags
              </router-link>
            </li>
          </ul>
        </li>
        <li class="sidebar-item">
          <a href="#greets" data-toggle="collapse" class="sidebar-link collapsed">
            <gift-icon size="1.5x" class="align-middle"></gift-icon>
            <span class="align-middle">Greets</span>
          </a>
          <ul id="greets" class="sidebar-dropdown list-unstyled collapse" data-parent="#sidebar">
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/secure/greets/add-greets">Add Greet
              </router-link>
            </li>
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/secure/greets/greet-list">Greet List
              </router-link>
            </li>
          </ul>
        </li>
        <li class="sidebar-item">
          <a href="#notifications" data-toggle="collapse" class="sidebar-link collapsed">
            <message-square-icon size="1.5x" class="align-middle"></message-square-icon>
            <span class="align-middle">Notifications</span>
          </a>
          <ul id="notifications" class="sidebar-dropdown list-unstyled collapse" data-parent="#sidebar">
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/secure/delivery-notification/SMS">SMS Template Settings
              </router-link>
            </li>
          </ul>
        </li>

        <!-- Driver -->
        <li class="sidebar-header section-title">Driver</li>
        <li class="sidebar-item">
          <a href="#mass-sms-hub" data-toggle="collapse" class="sidebar-link collapsed">
            <message-square-icon size="1.5x" class="align-middle"></message-square-icon>
            <span class="align-middle">Mass SMS Hub</span>
          </a>
          <ul id="mass-sms-hub" class="sidebar-dropdown list-unstyled collapse" data-parent="#sidebar">
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/secure/mass-sms-hub/onfleet-drivers-today">Onfleet Drivers Today
              </router-link>
            </li>
          </ul>
        </li>

        <!-- Reports -->
        <li class="sidebar-header section-title">Reports</li>
        <li class="sidebar-item">
          <a href="#reports" data-toggle="collapse" class="sidebar-link collapsed">
            <file-text-icon size="1.5x" class="align-middle"></file-text-icon>
            <span class="align-middle">Generate</span>
          </a>
          <ul id="reports" class="sidebar-dropdown list-unstyled collapse" data-parent="#sidebar">
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/secure/reports/reports-list">Reports config list
              </router-link>
            </li>
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/secure/reports/rules-list">Rules list
              </router-link>
            </li>
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/secure/reports/bulk-pre-pulls">Bulk Pre Pulls
              </router-link>
            </li>
          </ul>
        </li>

        <!-- Ops -->
        <li class="sidebar-header section-title">Operations</li>
        <li class="sidebar-item">
          <a href="#multiorder" data-toggle="collapse" class="sidebar-link collapsed">
            <gift-icon size="1.5x" class="align-middle"></gift-icon>
            <span class="align-middle">Order</span>
          </a>
          <ul id="multiorder" class="sidebar-dropdown list-unstyled collapse" data-parent="#sidebar">
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/secure/orders/bulkorder">Bulk Order
              </router-link>
            </li>
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/secure/orders/failedorder">Place Failed Order
              </router-link>
            </li>
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/secure/reports/pull-automation">Order Adjustments
              </router-link>
            </li>
          </ul>
        </li>
        <li class="sidebar-item">
          <a href="#sms" data-toggle="collapse" class="sidebar-link collapsed">
            <gift-icon size="1.5x" class="align-middle"></gift-icon>
            <span class="align-middle">SMS</span>
          </a>
          <ul id="sms" class="sidebar-dropdown list-unstyled collapse" data-parent="#sidebar">
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/secure/sms/history">SMS History
              </router-link>
            </li>
          </ul>
        </li>
        <!-- Unsubscribe -->
        <li class="sidebar-item">
          <a href="#unsubscribe" data-toggle="collapse" class="sidebar-link collapsed">
            <file-text-icon size="1.5x" class="align-middle"></file-text-icon>
            <span class="align-middle">Unsubscribe</span>
          </a>
          <ul id="unsubscribe" class="sidebar-dropdown list-unstyled collapse" data-parent="#sidebar">
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/secure/unsubscribe/phone">Unsubscribe Phone
              </router-link>
            </li>
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/secure/unsubscribe/email">Unsubscribe Email
              </router-link>
            </li>
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/secure/unsubscribe/customer-list">Edit Customer List
              </router-link>
            </li>
          </ul>
        </li>

        <li class="sidebar-item">
          <a href="#ops" data-toggle="collapse" class="sidebar-link collapsed">
            <file-text-icon size="1.5x" class="align-middle"></file-text-icon>
            <span class="align-middle">Tools</span>
          </a>
          <ul id="ops" class="sidebar-dropdown list-unstyled collapse" data-parent="#sidebar">
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/secure/ops/links">Ops Links
              </router-link>
            </li>
          </ul>
          <ul id="ops" class="sidebar-dropdown list-unstyled collapse" data-parent="#sidebar">
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/secure/ops/creds">Ops Credentials
              </router-link>
            </li>
          </ul>
        </li>
        <li>
          <a href="#giftsetAlertTemplate" data-toggle="collapse" class="sidebar-link collapsed">
            <gift-icon size="1.5x" class="align-middle"></gift-icon>
            <span class="align-middle">Giftset Alert</span>
          </a>
          <ul id="giftsetAlertTemplate" class="sidebar-dropdown list-unstyled collapse" data-parent="#sidebar">
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/secure/giftset/template">Template Dashboard
              </router-link>
            </li>
          </ul>
        </li>

        <li class="sidebar-item">
          <a data-toggle="collapse" href="#giftsetsalert" class="sidebar-link collapsed">
            <file-text-icon size="1.5x" class="align-middle"></file-text-icon>
            <span class="align-middle" to="/secure/giftset/alert">Alert List</span>
          </a>
          <ul id="giftsetsalert" class="sidebar-dropdown list-unstyled collapse" data-parent="#sidebar">
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/secure/giftset/alert">Giftset
              </router-link>
            </li>
          </ul>

        </li>
        <!-- Exports -->
        <li class="sidebar-header section-title">Labels</li>
        <li class="sidebar-item">
          <a href="#callia-export" data-toggle="collapse" class="sidebar-link collapsed">
            <file-text-icon size="1.5x" class="align-middle"></file-text-icon>
            <span class="align-middle">Callia</span>
          </a>
          <ul id="callia-export" class="sidebar-dropdown list-unstyled collapse" data-parent="#sidebar">
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/secure/exporter/callia-excel-to-pdf">SK, MTL & HX Excel to PDF
              </router-link>
            </li>
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/secure/exporter/large-labels">EDM, BC, CG, TR & MB NEW LABELS LINK
              </router-link>
            </li>
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/secure/exporter/callia-gift-box">Curated Gift Box
              </router-link>
            </li>
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/secure/exporter/callia-word-to-pdf">Word to PDF
              </router-link>
            </li>
          </ul>
        </li>
        <li class="sidebar-item">
          <a href="#callia-export-report" data-toggle="collapse" class="sidebar-link collapsed">
            <file-text-icon size="1.5x" class="align-middle"></file-text-icon>
            <span class="align-middle">Reports</span>
          </a>
          <ul id="callia-export-report" class="sidebar-dropdown list-unstyled collapse" data-parent="#sidebar">
            <li class="sidebar-item">
              <router-link class="sidebar-link" to="/secure/exporter/export-process-report">Label Processes
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</template>
<script>
import {
  TruckIcon,
  PackageIcon,
  FileTextIcon,
  GiftIcon,
  MapIcon,
  ShoppingBagIcon,
  MessageSquareIcon
} from "vue-feather-icons";
import { eventBus } from "../../main";

export default {
  data() {
    return {
      isHide: false,
      versionName: process.env.VUE_APP_PROJECT_VERSION,
      env: process.env.VUE_APP_ENV_NAME
    };
  },
  components: {
    // TruckIcon,
    PackageIcon,
    FileTextIcon,
    GiftIcon,
    MapIcon,
    ShoppingBagIcon,
    MessageSquareIcon
  },
  created() {
    eventBus.$on("hideLeftMenuEvent", data => {
      this.isHide = data;
    });
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/sidebar.scss";
</style>
