<template>
  <b-overlay :show="processing" rounded="sm" class="overlay-display w-100">
    <template #overlay>
      <div class="text-center">
        <b-spinner type="grow" variant="info" label="Loading..."></b-spinner>
      </div>
    </template>
    <template>
      <b-row class="py-3 text-center">
        <b-col offset-lg="4" lg="4" sm="12">
          <b-input-group size="sm">
            <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Search"
                @keyup.enter="filterDataTable"
            ></b-form-input>
            <b-input-group-append>
              <b-icon icon="search" class="icon"></b-icon>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col offset-lg="3" lg="6" sm="12">
          <b-form-checkbox-group
            v-model="filterOn"
            class="my-1 py-1 custom-filter-bg"
          >
            <b-form-checkbox value="name">Name</b-form-checkbox>
            <b-form-checkbox value="deliveryRate">Delivery Rate</b-form-checkbox>
            <b-form-checkbox value="zone">Zone</b-form-checkbox>
          </b-form-checkbox-group>
          <b-badge variant="light">Type and press enter to filter data. By default, it will filter all data. If need
            filter on specific field data, Select each option.
          </b-badge>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col lg="3" sm="6" class="left pr-0 mr-0">
          <h6 class="pagination-details">Show
            <b-form-select v-model="perPageCMB" :options="perPageCMBOptions"
                           class="col-3 ml-1 mr-2" size="sm"></b-form-select>
            Page
            <b-badge variant="secondary">
              {{ currentPage }}
            </b-badge>
            of
            <b-badge
              variant="secondary" class="mr-2">{{ numOfPages }}
            </b-badge>
            Count:
            <b-badge variant="dark">{{ totalRows}}
            </b-badge>
          </h6>
        </b-col>
        <b-col lg="4" sm="6">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="left"
            size="sm"
            @input="gridPagination"
          >
          </b-pagination>
        </b-col>
        <tooltip-modal></tooltip-modal>
      </b-row>
      <b-row>
        <b-col lg="12" sm="12">
          <vue-editable-grid
            class="my-grid-class"
            ref="postalCodeEditableGrid"
            id="myGrid"
            :item-height='35'
            :column-defs="columnDefs"
            rowDataKey="id"
            :row-data="rowData"
            @cell-updated="cellUpdated"
            @link-clicked="linkClicked"
          >
          </vue-editable-grid>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="3" sm="6" class="text-left pr-0 mr-0">
          <h6 class="pagination-details">Show
            <b-form-select v-model="perPageCMB" :options="perPageCMBOptions"
                           class="col-3 ml-1 mr-2" size="sm"></b-form-select>
            Page
            <b-badge variant="secondary">
              {{ currentPage }}
            </b-badge>
            of
            <b-badge
                variant="secondary" class="mr-2">{{ numOfPages }}
            </b-badge>
            Count:
            <b-badge variant="dark">{{
                totalRows
              }}
            </b-badge>
          </h6>
        </b-col>
        <b-col lg="4" sm="6">
          <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="left"
              size="sm"
              @input="gridPagination"
          >
          </b-pagination>
        </b-col>
      </b-row>
    </template>
  </b-overlay>
</template>
<script>
import {PlusIcon} from "vue-feather-icons";
import VueEditableGrid from 'vue-editable-grid'
import 'vue-editable-grid/dist/VueEditableGrid.css'

export default {
  components: {
    VueEditableGrid
  },
  props: ["processing", "rowData", "pageCount", "columnDefs", "pgCurrentPage", "pgTotalRows", "pgPerPage","putRequestArray"],
  name: "zoneDeliveryRateGrid",
  data() {
    return {
      filter: null,
      filterOn: [],
      currentPage: this.pgCurrentPage,
      totalRows: this.pgTotalRows,
      perPage: this.pgPerPage,
      numOfPages: 0,
      updateArray: [],
      deliveryRateID: null,
      zoneID: 0,
      newRowData: [],
      perPageCMBOptions: [],
      perPageCMB: '20'
    };
  },
  watch: {
    pgTotalRows: function (newVal, oldVal) {
      this.totalRows = this.pgTotalRows;
      this.updateNumberOfPages();
    },
    pgPerPage: function () {
      this.perPage = this.pgPerPage;
      this.updateNumberOfPages();
    },
    currentPage: function () {
      this.$emit('changePaginationPageEvent', this.currentPage);
    },
    pgCurrentPage: function (newVal, oldVal) {
      this.currentPage = this.pgCurrentPage;
    },
    perPageCMB: function (selected) {
      this.$emit('gridPaginationPerPageChange', selected);
    },
    putRequestArray: function (changedArrayValues) {
      if (changedArrayValues.length === 0) {
        this.updateArray = changedArrayValues;
      }
    }
  },
  mounted() {
    this.updateNumberOfPages();
  },
  methods: {
    filterDataTable(event) {
      let filterOpt = this.filterOn;
      if (this.filterOn.length === 0) {
        filterOpt = ['name', 'delivery_date', 'zone_name'];
      }
      this.$emit('filterDataTableEvent', {searchString: event.target.value, filterOn: filterOpt});
    },
    updateNumberOfPages() {
      this.numOfPages = Math.ceil(this.totalRows / this.perPage);
      this.perPageCMBOptions = ['20', '50', '100', '150', '200', '250', {value: this.pgTotalRows, text: 'All'}];
    },
    gridPagination(currentPage) {
      this.$emit('gridPaginationEvent', currentPage);
    },
    cellUpdated($event) {
      /** Maintain Seperate Update Row Array By Update Cells */
      let flag = true;
      if (this.updateArray.length === 0) {
        this.updateArray.push({
          'id': $event.row.id,
          'name': $event.row.name,
          'rate_name': $event.row.rate_name,
          'rate': $event.row.rate,
          'cutoff_time': $event.row.cutoff_time,
          'cutoff_date': $event.row.cutoff_date,
          'delivery_date': $event.row.delivery_date,
          'region_name': $event.row.region_name,
          'zone_id': $event.row.zone_id,
          'zone_name': $event.row.zone_name
        });
      } else {
        this.updateArray.map(qData => {
          if (qData.id === $event.row.id) {
            flag = false;
          }
        });
        if (flag) {
          this.updateArray.push({
            'id': $event.row.id,
            'name': $event.row.name,
            'rate_name': $event.row.rate_name,
            'rate': $event.row.rate,
            'cutoff_time': $event.row.cutoff_time,
            'cutoff_date': $event.row.cutoff_date,
            'delivery_date': $event.row.delivery_date,
            'region_name': $event.row.region_name,
            'zone_id': $event.row.zone_id,
            'zone_name': $event.row.zone_name
          });
        }
      }

      const updatedFieldArray = [];
      switch ($event.column.field) {
        case "cutoff_time":
          updatedFieldArray.push({
            'updatePoint': 'cutoff_time',
            'id': $event.row.id,
            'cutoff_time': $event.value
          });
          break;
        case "cutoff_date":
          updatedFieldArray.push({
            'updatePoint': 'cutoff_date',
            'id': $event.row.id,
            'cutoff_date': $event.value
          });
          break;
        case "delivery_date":
          updatedFieldArray.push({
            'updatePoint': 'delivery_date',
            'id': $event.row.id,
            'delivery_date': $event.value
          });
          break;
      }

      this.updateArray.map((qData, index) => {
        if (qData.id === updatedFieldArray[0].id) {
          switch (updatedFieldArray[0].updatePoint) {
            case "cutoff_time":
              this.updateArray[index]['cutoff_time'] = updatedFieldArray[0].cutoff_time
              break;
            case "cutoff_date":
              this.updateArray[index]['cutoff_date'] = updatedFieldArray[0].cutoff_date
              break;
            case "delivery_date":
              this.updateArray[index]['delivery_date'] = updatedFieldArray[0].delivery_date
              break;
          }
        }
      })
      this.$emit("updateRowArray", this.updateArray);
    },
    linkClicked($event) {
      const deleteArray = {'id': $event.rowData.id, 'name': $event.rowData.name}
      this.$emit("deleteZoneDeliveryRate", deleteArray);
    },
  }
}
</script>
<style>
.my-grid-class {
  height: 450px;
}

tr.filters-row {
  background-color: #343a40;
}

thead {
  background-color: #636e78 !important;
}

.header-content {
  color: white;
  font-weight: bold;
}

th.filter input[data-v-af99a9fe] {
  font-size: 0.9rem;
  color: #15cfd8;
}

th[data-v-af99a9fe]:after {
  background-color: white;
}

th.filter input[data-v-af99a9fe] {
  padding-left: 27px;
}

.cell-content > a {
  text-decoration: none;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 8px;
  color: #fff;
  background-color: #f75050;
}

/*pagination*/
.pagination .spinner-grow {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  vertical-align: middle;
  background-color: #f8f9fa;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: .75s linear infinite spinner-grow;
  animation: .75s linear infinite spinner-grow;
}

.grid-tools {
  display: none !important;
}

.cell .selected-top, .selected-right, .selected-bottom, .selected-left {
  border-color: #17a2b8 !important;
}

.pagination-sm .page-link {
  padding: 0.25rem 1rem;
}

.primary-btn {
  color: white;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.primary-btn:hover {
  color: white;
  background-color: #18b2cb;
  border-color: #18b2cb;
}

.primary-btn:focus {
  color: white;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgb(177 179 179 / 23%);
}

.page-item.active .page-link {
  color: #fff;
  background-color: #18a2b8;
  border-color: #18a2b8;
}

.pagination-details {
  font-size: 0.97rem;
}

table.grid-table {
  border-radius: 3px;
}
</style>
