<template>
  <b-modal id="modal-add-zone-delivery-rate" ref="modal" title="Add New Zone Delivery Rate" no-close-on-backdrop
    @show="resetModal" @hidden="resetModal" @ok="handleOk" ok-variant="primary primary-btn" ok-title="Add"
    cancel-title="Close">
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group label="Name" label-for="name" invalid-feedback="Name is required" valid-feedback="Look Good">
        <b-form-input id="name" v-model="name" readonly :state="validateName" @keyup="keyupValidationName"
          required></b-form-input>
        <p v-if="isHidden = (this.deliveryRate && this.zoneModel) ? false : true" class="text-info">* Fill the zone and
          delivery rate fields below to generate name.</p>
      </b-form-group>
      <b-form-group label="Province" label-for="province" invalid-feedback="Choose a Province">
        <v-select id="province" :options="provinceOptions" placeholder="choose..." v-model="provinceModel"
          :clearable="true" :class="validateProvince" @input="provinceSelectedValue($event)">
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!provinceModel" v-bind="attributes" v-on="events" />
          </template>
        </v-select>
      </b-form-group>
      <b-form-group label="Region" label-for="region" invalid-feedback="Choose a Region">
        <v-select id="region" :options="regionOptions" placeholder="choose..." v-model="regionModel" :clearable="true"
          :class="validateRegion" @input="regionSelectedValue($event)">
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!regionModel" v-bind="attributes" v-on="events" />
          </template>
        </v-select>
      </b-form-group>
      <b-form-group label="Zone" label-for="zone" invalid-feedback="Choose a Zone">
        <v-select id="zone" :options="zoneOptions" placeholder="choose..." v-model="zoneModel" :clearable="true"
          :class="validateZone" @input="zoneSelectedValue($event)">
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!zoneModel" v-bind="attributes" v-on="events" />
          </template>
        </v-select>
      </b-form-group>
      <b-form-group label="DeliveryRate" label-for="deliveryRate" invalid-feedback="Input a DeliveryRate">
        <b-form-input id="deliveryRate" v-model="deliveryRate" @input="deliveayRateSelectedValue($event)"></b-form-input>
      </b-form-group>
      <b-form-group label-for="deliveryRate" invalid-feedback="Choose a Rate">
        <v-select id="rate" :options="rateOptions" placeholder="choose..." v-model="rate" @input="rateSelectedValue($event)"/>
      </b-form-group>
      <b-form-group label="Cutoff Time" label-for="cutoffTime" invalid-feedback="">
        <b-form-timepicker id="cutoffTime" reset-button v-model="cutoffTime"
          locale="en"></b-form-timepicker>
        {{ cutoffTime }}
      </b-form-group>
      <b-form-group label="Cutoff Date" label-for="cutoffDate" invalid-feedback="">
        <b-form-datepicker
          id="cutoffDate"
          :date-format-options="{
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          }"
          v-model="cutoffDate"
          :date-disabled-fn="dateDisabled"
          class="mb-2"
          required
        ></b-form-datepicker>
        {{ cutoffDate }}
      </b-form-group>
      <b-form-group label="Delivery Date" label-for="delivery_date" invalid-feedback="">
        <b-form-datepicker
          id="delivery_date"
          :date-format-options="{
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          }"
          v-model="deliveryDate"
          :date-disabled-fn="dateDisabled"
          class="mb-2"
          required
        ></b-form-datepicker>
        {{ deliveryDate }}
      </b-form-group>
    </form>
  </b-modal>
</template>
<script>
import Vue from "vue";
import vSelect from "vue-select";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import advancedFormat from "dayjs/plugin/advancedFormat";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isoWeek from "dayjs/plugin/isoWeek";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);
dayjs.extend(advancedFormat);

Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";

export default {
  props: ["zoneOptions", "provinceOptions", "regionOptions"],
  name: "add-region-popup",
  data() {
    return {
      validateName: null,
      validateProvince: "",
      validateRegion: "",
      validateZone: "",
      validateDeliveryRate: "",
      validateApplicableDate: "",

      provinceModel: "",
      regionModel: "",
      zoneModel: "",
      deliveryRate: "Anytime (8am - 8pm)",
      rate: "$10",
      cutoffTime: null,
      cutoffDate: null,
      deliveryDate: null,
      rateOptions: [
        "$0", "$1", "$2", "$3", "$4", "$5", "$6", "$7", "$8", "$9",
        "$10", "$11", "$12", "$13", "$14", "$15", "$16", "$17", "$18", "$19",
        "$20", "$21", "$22", "$23", "$24", "$25", "$26", "$27", "$28", "$29",
        "$30", "$31", "$32", "$33", "$34", "$35", "$36", "$37", "$38", "$39",
        "$40", "$41", "$42", "$43", "$44", "$45", "$46", "$47", "$48", "$49",
      ],

      name: "",

      alert: {
        status: false,
        color: "",
        message: ""
      }
    };
  },
  computed: {},
  methods: {
    clearRegionCMB() {
      this.validateRegion = "";
      this.regionModel = "";
    },
    clearZoneCMB() {
      this.validateZone = "";
      this.zoneModel = "";
      this.name = "";
    },
    clearDeliveryRateCMB() {
      this.validateDeliveryRate = "";
      this.deliveryRate = "";
    },
    clearApplicableDateCMB() {
      this.validateApplicableDate = "";
      this.applicableDate = "";
    },

    show() {
      this.$refs.modal.show();
    },
    provinceSelectedValue(event) {
      if (event !== null) {
        this.validateProvince = "";
      } else {
        this.validateProvince = "is-invalid";
      }
      this.clearRegionCMB();
      this.clearZoneCMB();
      this.clearApplicableDateCMB();
      this.$emit('changeProvince', event.value);
    },
    regionSelectedValue(event) {
      if (event !== null) {
        this.validateRegion = "";
      } else {
        this.validateRegion = "is-invalid";
      }
      this.clearZoneCMB();
      this.clearApplicableDateCMB();
      this.$emit('changeRegion', event.value);
    },
    zoneSelectedValue(event) {
      if (event !== null) {
        this.validateZone = "";
        this.name = event.label + " - " + (this.deliveryRate || "") + " - " + (this.rate.split(".")[0] || "");
      } else {
        this.validateZone = "is-invalid";
      }
    },
    rateSelectedValue(event) {
      if (event !== null) {
        this.validateZone = "";
        this.name = this.zoneModel?.label + " - " + (this.deliveryRate || "") + " - " + (this.rate.split(".")[0] || "");
      } else {
        this.validateZone = "is-invalid";
      }
    },
    deliveayRateSelectedValue(event) {
      if (event !== null) {
        this.validateZone = "";
        this.name = this.zoneModel?.label + " - " + (this.deliveryRate || "") + " - " + (this.rate.split(".")[0] || "");
      } else {
        this.validateZone = "is-invalid";
      }
    },
    applicableDateSelectedValue(event) {
      if (event !== null) {
        this.validateApplicableDate = "";
      } else {
        this.validateApplicableDate = "is-invalid";
      }
    },
    validateProvinceCMB() {
      if (this.provinceModel !== null) {
        if (this.provinceModel.value == null) {
          this.validateProvince = "is-invalid";
        } else {
          this.validateProvince = "";
        }
      } else {
        this.validateProvince = "is-invalid";
      }
    },
    validateRegionCMB() {
      if (this.regionModel !== null) {
        if (this.regionModel.value == null) {
          this.validateRegion = "is-invalid";
        } else {
          this.validateRegion = "";
        }
      } else {
        this.validateRegion = "is-invalid";
      }
    },
    validateZoneCMB() {
      if (this.zoneModel) {
        if (this.zoneModel.value == null) {
          this.validateZone = "is-invalid";
        } else {
          this.validateZone = "";
        }
      } else {
        this.validateZone = "is-invalid";
      }
    },
    keyupValidationName() {
      if (this.name !== "") {
        this.validateName = true;
      } else {
        this.validateName = false;
      }
    },
    checkFormValidity() {
      const fullFormValidity = this.$refs.form.checkValidity();

      //validate name
      if (this.$refs.form.name.checkValidity()) {
        this.validateName = true;
      } else {
        this.validateName = false;
      }

      this.validateProvinceCMB();
      this.validateRegionCMB();
      this.validateZoneCMB();

      return fullFormValidity;
    },
    dateDisabled(ymd, date) {
      const dateSelected = dayjs.tz(ymd,  'America/Winnipeg');
      const currentTimeStamp = dayjs().tz('America/Winnipeg');
      const today = currentTimeStamp.startOf('day');
      if (dateSelected.isBefore(today)) {
        return true;
      }
      return false;
    },
    resetModal() {
      this.validateProvince = "";
      this.validateRegion = "";
      this.validateZone = "";
      this.validateApplicableDate = "";
      this.name = "";
      this.validateName = null;
    },
    async handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      await this.handleSubmit();
    },
    async handleSubmit() {
      // Exit when the form isn't valid
      try {
        if (!this.checkFormValidity()) {
          return null;
        }

        const zoneDeliveryRatePostArray = {
          name: this.name,
          zone_id: this.zoneModel?.value,
          rate_name: this.deliveryRate + " - " + this.rate.split(".")[0],
          rate: this.rate,
          cutoff_time: this.cutoffTime.substring(0, 5),
          cutoff_date: this.cutoffDate,
          delivery_date: this.deliveryDate
        };
        console.log("zoneDeliveryRatePostArray:", zoneDeliveryRatePostArray);

        const response = await this.axios({
          method: "POST",
          url: `${process.env.VUE_APP_API_ADMIN_API_ENDPOINT}/rates/special/zone/rate`,
          headers: {
            Authorization: `Bearer ${this.$store.getters.idToken}`
          },
          data: zoneDeliveryRatePostArray
        });

        if (response.status == 201) {
          this.$nextTick(() => {
            this.$bvModal.hide("modal-add-zone-delivery-rate");
          });

          this.$emit("success", response.data['success']);
        } else {
          this.$emit("error", response.data['error']);
        }
      } catch (errorResponse) {
        this.$emit("error");
        console.error("Error: ", errorResponse);
      }
    }
  }
};
</script>